import React from 'react';

import { Question } from './Onboarding';

export const QuestionCheckbox = ({ question, setState, state }: { question: Question, setState: Function, state: any }) => {
  const [value, setValue] = React.useState(false);
  const [error, setError] = React.useState('');

  console.log("QuestionCheckbox", value)
  const handleChange = () => {
    if (state[question.name] !== value) {
      const newValue = !value;
      setValue(newValue);
      setState({ ...state, [question.name]: newValue });
    }

    // if (question.errorConditions.some((errorCondition) => errorCondition.condition(value))) {
    //   setError(
    //     question.errorConditions.find((errorCondition) =>
    //       errorCondition.condition(value)).errorContent
    //   );
    // } else {
    //   setError('');
    // }
  }

  return (
    <div className="form-group mb-2">
      <label className="form-label">
        <input
          type="checkbox"
          name={question.name}
          value={value}
          onChange={handleChange}
          className="form-check-input me-2"
        />
        {question.label}
      </label>
      {/* Description */}
      <div>{question.description}</div>
      {/* Error */}
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );

}
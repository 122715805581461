import React from 'react';

import { Question } from './Onboarding';

export const QuestionCheckboxes = ({ question, setState, state, required }: { question: Question, setState: Function, state: any, required?: boolean }) => {
  const [value, setValue] = React.useState(state[question.name] || '');
  const [error, setError] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Values should be stored as a comma delimited string
    // When checkbox is deselected, it should be removed from the string
    // When checkbox is selected, it should be added to the string

    // Split the string into an array
    let selectedValues = value.split(',');
    // If the checkbox is checked
    if (event.target.checked) {
      // Add the value to the array
      selectedValues.push(event.target.value);
    } else {
      // Remove the value from the array
      selectedValues = selectedValues.filter((val) => val !== event.target.value);
    }

    // Join the array back into a string
    setValue(selectedValues.join(','));
    setState({ ...state, [question.name]: selectedValues.join(',') });

    if (question.errorConditions.some((errorCondition) => errorCondition.condition(selectedValues.join(',')))) {
      // setError(question.errorConditions.find((errorCondition) => errorCondition.condition(selectedValues.join(','))?.errorContent);
    } else {
      setError('');
    }
  }

  return (
    <>

      <div className="form-group mb-2">
        <label className="form-label">{question.label}</label>
        {question.options?.map((option, index) => {
          return (
            <div key={index} className="form-check">
              <input
                type="checkbox"
                name={question.name}
                value={option.value}
                onChange={handleChange}
                className="form-check-input"
                checked={value.split(',').includes(option.value)}
              />
              <label className="form-check-label">{option.label}</label>
            </div>
          );
        })}
        {/* Description */}
        <div>{question.description}</div>
        {/* Error */}
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </>
  );

}
import ReactOnRails from 'react-on-rails';

import { AddressFields } from '../bundles/AddressFields/AddressFields';
import { LicenseFields } from '../bundles/LicenseFields/LicenseFields';
import { Onboarding } from '../bundles/Onboarding/Onboarding';
import { SignUp } from '../bundles/SignUp/SignUp';
import { DOIField } from '../bundles/DOIField/DOIField';
import { DOBField } from '../bundles/DOBField/DOBField';
import { InfoBox, ReportingCompanyInfoBox, CompanyApplicantInfoBox, BeneficialOwnerInfoBox, CTARepInfoBox } from '../bundles/InfoBox/InfoBox';
import { EINField } from '../bundles/EINField/EINField';
import { ValidatedSubmitButton } from '../bundles/ValidatedSubmitButton/ValidatedSubmitButton';
import { BeneficialOwnerForm } from '../bundles/BeneficialOwner/BeneficialOwnerForm';
import { ProfileForm } from '../bundles/ProfileForm/ProfileForm';
import { ProfileAdditionalForm } from '../bundles/ProfileForm/ProfileAdditionalForm';

import { Notes } from '../bundles/Notes/Notes';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
    AddressFields,
    LicenseFields,
    Onboarding,
    SignUp,
    DOIField,
    DOBField,
    InfoBox,
    ReportingCompanyInfoBox,
    CompanyApplicantInfoBox,
    BeneficialOwnerInfoBox,
    CTARepInfoBox,
    EINField,
    ValidatedSubmitButton,
    BeneficialOwnerForm,
    Notes,
    ProfileForm,
    ProfileAdditionalForm
});

import { atom } from 'nanostores';

export const $ValidationsArray = atom<string[]>([]);

// Prevent duplicate fields from being added to the validations array
export function addValidationField(name: string) {
  if (!$ValidationsArray.get().includes(name)) {
    $ValidationsArray.set([...$ValidationsArray.get(), name]);
  }
}

// Remove field from validations array
export function removeValidationField(name: string) {
  $ValidationsArray.set($ValidationsArray.get().filter((field) => field !== name));
}
import React from 'react';

import { CountryField, CountriesWithStates } from '../CountryField/CountryField';
import { StateField } from '../StateField/StateField';

import { useStore } from '@nanostores/react';
import { $ValidationsArray, addValidationField, removeValidationField } from '../nanostores/ValidationsArray';

interface LicenseFieldsProps {
  types_of_licenses: Array<[string, string, {
    license_number: boolean,
    license_country: boolean,
    license_state: boolean,
    identification_expiration_date: boolean
  }]>;
  modelName: string;
  selectedTypeOfLicense?: string;
  selectedLicenseNumber?: string;
  selectedLicenseCountry?: string;
  selectedLicenseState?: string;
  selectedIdentificationExpirationDate?: string;
  prefix?: string;
  onChange?: any;
  fieldsDisabled?: boolean;
  fieldsRequired?: boolean;
}

export const LicenseFields = ({
  modelName,
  selectedTypeOfLicense = '',
  selectedLicenseNumber = '',
  selectedLicenseCountry = '',
  selectedLicenseState = '',
  selectedIdentificationExpirationDate = '',
  prefix = '',
  onChange = () => { },
  fieldsDisabled = false,
  fieldsRequired = true
}: LicenseFieldsProps) => {
  const [typeOfLicense, setTypeOfLicense] = React.useState(selectedTypeOfLicense);
  const [licenseNumber, setLicenseNumber] = React.useState(selectedLicenseNumber);
  const [licenseCountry, setLicenseCountry] = React.useState(selectedLicenseCountry);
  const [licenseState, setLicenseState] = React.useState(selectedLicenseState);
  const [identificationExpirationDate, setIdentificationExpirationDate] = React.useState(selectedIdentificationExpirationDate);

  const validationsArray = useStore($ValidationsArray);

  const typeOfLicenseObject = TYPES_OF_LICENSES.find(
    license => license[1] === typeOfLicense
  );

  const selectedCountryObject = CountriesWithStates.find(
    c => c.name === licenseCountry
  );

  const selectedCountryStates: Array<string> | undefined = selectedCountryObject
    ? selectedCountryObject.states
    : [];

  // Is the license expiration date within 90 days of expiring but not yet expired?
  const isLicenseExpirationDateWithin90Days = () => {
    if (!identificationExpirationDate) return false;

    const expirationDate = new Date(identificationExpirationDate);
    const today = new Date();
    const ninetyDaysFromToday = new Date(today);
    ninetyDaysFromToday.setDate(today.getDate() + 90);

    return expirationDate < ninetyDaysFromToday && expirationDate > today;
  }

  // Is the license expired?
  const isLicenseExpired = () => {
    if (!identificationExpirationDate) return false;

    const expirationDate = new Date(identificationExpirationDate);
    const today = new Date();

    return expirationDate < today;
  }

  React.useEffect(() => {
    console.log('typeOfLicense', typeOfLicense)
    // If typeOfLicenseObject.identification_expiration_date is false, set identificationExpirationDate to ''
    if (typeOfLicenseObject && !typeOfLicenseObject[2].identification_expiration_date) setIdentificationExpirationDate('');
    // If typeOfLicenseObject.license_number is false, set licenseNumber to ''
    if (typeOfLicenseObject && !typeOfLicenseObject[2].license_number) setLicenseNumber('');
    // If typeOfLicenseObject.license_country is false, set licenseCountry to ''
    if (typeOfLicenseObject && !typeOfLicenseObject[2].license_country) setLicenseCountry('');
    // If typeOfLicenseObject.license_state is false, set licenseState to ''
    if (typeOfLicenseObject && !typeOfLicenseObject[2].license_state) setLicenseState('');

    // If typeOfLicense doesnt include the word "foreign", set licenseCountry to "United States of America"
    if (typeOfLicense && !typeOfLicense.toLowerCase().includes("foreign")) {
      setLicenseCountry("United States of America");
    }
  }, [typeOfLicense]);

  React.useEffect(() => {
    if (!fieldsDisabled && fieldsRequired && isLicenseExpired()) {
      addValidationField(`${modelName}[${prefix}identification_expiration_date]`);
    } else {
      removeValidationField(`${modelName}[${prefix}identification_expiration_date]`);
    }
  }, [identificationExpirationDate, fieldsDisabled, fieldsRequired]);

  // If any of the state objects change, trigger onChange
  // typeOfLicense
  React.useEffect(() => {
    onChange(`${prefix}type_of_license`, typeOfLicense);
  }, [typeOfLicense]);

  // licenseNumber
  React.useEffect(() => {
    onChange(`${prefix}license_number`, licenseNumber);
  }, [licenseNumber]);

  // licenseCountry
  React.useEffect(() => {
    onChange(`${prefix}license_country`, licenseCountry);
  }, [licenseCountry]);

  // licenseState
  React.useEffect(() => {
    onChange(`${prefix}license_state`, licenseState);
  }, [licenseState]);

  // identificationExpirationDate
  React.useEffect(() => {
    onChange(`${prefix}identification_expiration_date`, identificationExpirationDate);
  }, [identificationExpirationDate]);

  return (
    <div className="license-fields">
      <TypeOfLicenseFields
        types_of_licenses={TYPES_OF_LICENSES}
        modelName={modelName}
        setTypeOfLicense={setTypeOfLicense}
        typeOfLicense={typeOfLicense}
        prefix={prefix}
        disabled={fieldsDisabled}
        required={fieldsRequired}
      />

      {/* Text Field identification_expiration_date */}
      {typeOfLicenseObject && typeOfLicenseObject[2].identification_expiration_date && (
        <div className="form-floating my-2">
          <input type="date"
            placeholder="Identification Expiration Date"
            name={`${modelName}[${prefix}identification_expiration_date]`}
            value={identificationExpirationDate}
            onChange={(e) => setIdentificationExpirationDate(e.target.value)}
            className="form-control"
            required={fieldsRequired}
            disabled={fieldsDisabled}
          />
          <label htmlFor={`${modelName}[${prefix}identification_expiration_date]`}>
            Identification Expiration Date*
          </label>
        </div>
      )}
      {typeOfLicenseObject && !typeOfLicenseObject[2].identification_expiration_date && (
        <input type="hidden"
          name={`${modelName}[${prefix}identification_expiration_date]`}
          value={identificationExpirationDate}
        />
      )}
      {/* If the license is within 90 days of expiring, warn the user using an alert-warning */}
      {isLicenseExpirationDateWithin90Days() && (
        <div className="alert alert-warning" role="alert">
          Your identification is within 90 days of expiring and will need to be updated soon.
        </div>
      )}
        {/* If the license is expired, warn the user using an alert-danger */}
        {!fieldsDisabled && isLicenseExpired() && (
          <div className="alert alert-danger" role="alert">
            Your identification is expired and can't be used.
          </div>
        )}

      {/* license_number */}
      {typeOfLicenseObject && typeOfLicenseObject[2].license_number && (
        <div className="form-floating my-2">
          <input type="text"
            placeholder="License Number*"
            name={`${modelName}[${prefix}license_number]`}
            value={licenseNumber}
            onChange={(e) => setLicenseNumber(e.target.value)}
            className="form-control"
            required={fieldsRequired}
            disabled={fieldsDisabled}
          />
          <label htmlFor={`${modelName}[${prefix}license_number]`}>
            License Number
          </label>
        </div>
      )}
      {typeOfLicenseObject && !typeOfLicenseObject[2].license_number && (
        <input type="hidden"
          name={`${modelName}[${prefix}license_number]`}
          value={licenseNumber}
        />
      )}

      {/* license_country */}
      {typeOfLicenseObject && typeOfLicenseObject[2].license_country && (
        <CountryField
          selectedCountry={licenseCountry}
          modelName={modelName}
          fieldName="license_country"
          setCountryFunction={setLicenseCountry}
          disabled={fieldsDisabled}
          required={fieldsRequired}
        />
      )}
      {typeOfLicenseObject && !typeOfLicenseObject[2].license_country && (
        <input type="hidden"
          name={`${modelName}[${prefix}license_country]`}
          value={licenseCountry}
        />
      )}

      {/* license_state */}
      {typeOfLicenseObject && typeOfLicenseObject[2].license_state && (
        <StateField
          states={selectedCountryStates}
          selectedState={licenseState}
          setStateFunction={setLicenseState}
          fieldName="license_state"
          modelName={modelName}
          prefix={prefix}
          disabled={fieldsDisabled}
          required={fieldsRequired}
        />
      )}
      {typeOfLicenseObject && !typeOfLicenseObject[2].license_state && (
        <input type="hidden"
          name={`${modelName}[${prefix}license_state]`}
          value={licenseState}
        />
      )}
    </div>
  );
}

const TypeOfLicenseFields = ({
  types_of_licenses,
  modelName,
  setTypeOfLicense,
  typeOfLicense,
  prefix = '',
  disabled = false,
  required = true
}) => {
  return (
    <div className="license-types">
      {types_of_licenses.map((license, index) => {
        const licenseDescription = license[0];
        const licenseValue = license[1];
        const licenseName = license[1].toLowerCase()
          .replace(" ", "_")
          .replace(".", "");
        const licenseId = `type_of_license_${licenseName}`;

        return (
          <div className="form-check" key={index}>
            <input type="radio"
              id={licenseId}
              value={licenseValue}
              className="form-check-input"
              required={required}
              disabled={disabled}
              onChange={() => setTypeOfLicense(licenseValue)}
              checked={licenseValue === typeOfLicense}
              name={`${modelName}[${prefix}type_of_license]`}
            />
            <label className="form-check-label" htmlFor={licenseId}>
              {licenseDescription}
            </label>
          </div>
        )
      })}
    </div>
  );
}

const TYPES_OF_LICENSES = [
  [
    'A non-expired U.S. Driver\'s License (including any Driver\'s Licenses issued by a commonwealth, territory, or possession of the United States)',
    'U.S. driver\'s license',
    {
      license_number: true,
      license_country: true,
      license_state: true,
      identification_expiration_date: true
    }
  ],
  ['A non-expired identification document issued by a U.S. state or local government, or Indian Tribe',
    'U.S. identification document',
    {
      license_number: true,
      license_country: true,
      license_state: true,
      identification_expiration_date: true
    }
  ],
  [
    'A non-expired passport issued by the U.S. government',
    'U.S. passport',
    {
      license_number: true,
      license_country: true,
      license_state: false,
      identification_expiration_date: true
    }
  ],
  [
    'A non-expired passport issued by a foreign government (only when an individual does not have one of the other three forms of identification listed above).', 'Foreign passport',
    {
      license_number: true,
      license_country: true,
      license_state: true,
      identification_expiration_date: true
    }
  ]
]

import * as React from 'react';
import { useStore } from '@nanostores/react';
import { $ValidationsArray } from '../nanostores/ValidationsArray';

export const ValidatedSubmitButton: React.FC = () => {
  const validationsArray = useStore($ValidationsArray);

  const [disabled, setDisabled] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    if (validationsArray.length > 0) {
      setDisabled(true);
      setErrorMessage('Please correctly fill out all required fields.');
    } else {
      setDisabled(false);
      setErrorMessage('');
    }
  }, [validationsArray]);

  return (
    <div>
      <button type="submit" className="btn btn-primary" disabled={disabled}>
        {errorMessage || 'Submit'}
      </button>
    </div>
  );
}
import React from 'react';

import { Question } from './Onboarding';

export const QuestionSelect = ({ question, setState, state, required }: { question: Question, setState: Function, state: any, required?: boolean }) => {
  const [value, setValue] = React.useState(state[question.name] || '');
  const [error, setError] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value);
    setState({ ...state, [question.name]: event.target.value });
    if (question.errorConditions.some((errorCondition) => errorCondition.condition(event.target.value))) {
      setError(question.errorConditions.find((errorCondition) => errorCondition.condition(event.target.value))?.errorContent);
    } else {
      setError('');
    }
  }
  const alertType = question.alertType || 'danger';

  // Is the question label longer than 50 characters?
  const labelTooLong = question.label.length > 50;
  return (
    <>
      {labelTooLong &&
        // Make text smaller if label is too long
        <label className="form-label mt-3 p-2">{question.label}</label>
      }
      <div className="form-floating mb-3">
        <select
          name={question.name}
          value={value}
          onChange={handleChange}
          className="form-select"
        >
          <option value="">Select</option>
          {question.options?.map((option, index) => (
            <option
              key={index}
              value={option.value}
              selected={option.value === value}
            >{option.label}</option>
          ))}
        </select>
        {!labelTooLong && <label className="form-label">{question.label}</label>}
        {labelTooLong && <label className="form-label">Selection</label>}
        {/* Description */}
        <div className="mt-2">{question.description}</div>
        {/* Error */}
        {error && <div className={`alert alert-${alertType}`}>{error}</div>}
      </div>
    </>
  );

}
import React from 'react';

import { Question } from './Onboarding';

export const QuestionTextField = ({ question, setState, state, required }: { question: Question, setState: Function, state: any, required?: boolean }) => {
  const [value, setValue] = React.useState(state[question.name] || '');
  console.log("QuestionTextField", question, state);
  const [error, setError] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setState({ ...state, [question.name]: event.target.value });
    if (question.errorConditions.some((errorCondition) => errorCondition.condition(event.target.value))) {
      setError(question.errorConditions.find((errorCondition) => errorCondition.condition(event.target.value))?.errorContent);
    } else {
      setError('');
    }
  }

  return (
    <div className="form-floating mb-2">
      <input
        type="text"
        name={question.name}
        value={value}
        onChange={handleChange}
        className="form-control"
      />
      <label className="form-label">{question.label}</label>
      {/* Description */}
      <div>{question.description}</div>
      {/* Error */}
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );

}
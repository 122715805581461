import React, { useState, useEffect } from 'react';

export const Notes = ({ profileId, authenticityToken }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState({ content: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredNoteId, setHoveredNoteId] = useState(null);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    try {
      const response = await fetch(`/notes.json?profile_id=${profileId}`);
      if (!response.ok) throw new Error('Failed to fetch notes');
      const data = await response.json();
      setNotes(data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
    } catch (err) {
      setError('Failed to load notes');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewNote(prev => ({ ...prev, [name]: value }));
  };

  const addNote = async () => {
    if (newNote.content) {
      const data = {
        note: {
          content: newNote.content,
          profile_id: profileId
        }
      };

      try {
        const response = await fetch(`/notes.json`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticityToken
          },
          body: JSON.stringify(data)
        });
        if (response.status === 422) {
          window.location.reload();
          return;
        }
        if (!response.ok) throw new Error('Failed to create note');
        const createdNote = await response.json();
        setNotes(prev => [createdNote, ...prev]);
        setNewNote({ content: '' });
      } catch (err) {
        setError('Failed to create note');
      }
    }
  };

  const deleteNote = async (id) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        const response = await fetch(`/notes/${id}.json`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': authenticityToken
          }
        });
        if (response.status === 422) {
          window.location.reload();
          return;
        }
        if (!response.ok) throw new Error('Failed to delete note');
        setNotes(prev => prev.filter(note => note.id !== id));
      } catch (err) {
        setError('Failed to delete note');
      }
    }
  };

  const startEditing = (note) => {
    setEditingNoteId(note.id);
    setEditedContent(note.content);
  };

  const cancelEditing = () => {
    setEditingNoteId(null);
    setEditedContent('');
  };

  const saveEditedNote = async (id) => {
    try {
      const response = await fetch(`/notes/${id}.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authenticityToken
        },
        body: JSON.stringify({ note: { content: editedContent } })
      });
      if (response.status === 422) {
        window.location.reload();
        return;
      }
      if (!response.ok) throw new Error('Failed to update note');
      const updatedNote = await response.json();
      setNotes(prev => prev.map(note => note.id === id ? updatedNote : note));
      setEditingNoteId(null);
      setEditedContent('');
    } catch (err) {
      setError('Failed to update note');
    }
  };

  if (loading) return <div className="text-center"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="card">
      <div className="card-body">
        <h2 className="card-title mb-4">Notes</h2>

        <form className="mb-4" onSubmit={(e) => { e.preventDefault(); addNote(); }}>
          <div className="mb-3">
            <textarea
              className="form-control"
              name="content"
              value={newNote.content}
              onChange={handleInputChange}
              placeholder="Note Content"
              rows="3"
            ></textarea>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
          >
            <i className="fas fa-plus"></i> Add Note
          </button>
        </form>

        <div>
          {notes.map(note => (
            <div
              key={note.id}
              className="card mb-3 position-relative"
              onMouseEnter={() => setHoveredNoteId(note.id)}
              onMouseLeave={() => setHoveredNoteId(null)}
            >
              <div className="card-body">
                {editingNoteId === note.id ? (
                  <div>
                    <textarea
                      className="form-control mb-2"
                      value={editedContent}
                      onChange={(e) => setEditedContent(e.target.value)}
                      rows="3"
                    ></textarea>
                    <button
                      onClick={() => saveEditedNote(note.id)}
                      className="btn btn-link btn-sm me-2"
                    >
                      <i className="fas fa-check"></i>
                    </button>
                    <button
                      onClick={cancelEditing}
                      className="btn btn-link btn-sm"
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                ) : (
                  <>
                    <p className="card-text">{note.content}</p>
                    <p className="card-text">
                      <small className="text-muted">
                        By {note.author} on {new Date(note.created_at).toLocaleString()}
                      </small>
                    </p>
                    {hoveredNoteId === note.id && (
                      <>
                        <button
                          onClick={() => startEditing(note)}
                          className="btn btn-link btn-sm position-absolute top-0 end-0 m-2 me-5"
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        <button
                          onClick={() => deleteNote(note.id)}
                          className="btn btn-link btn-sm position-absolute top-0 end-0 m-2"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

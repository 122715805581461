import React from 'react';
// Component for Date of Birth Field that warns that the date of birth must be 18 years old if the user is under 18

export const DOBField = ({
  modelName,
  fieldName = 'date_of_birth',
  selectedDateOfBirth,
  errorMessage = 'Company applicant must be at least 18 years old.',
  onChange,
  disabled = false,
  required = true,
  prefix = '',
  value // New prop to accept a value
}) => {
  // Parse string selectedDateOfBirth (YYYY-MM-DD) to Date object
  const [dateOfBirth, setDateOfBirth] = React.useState(value || selectedDateOfBirth);
  const handleChange = (e) => setDateOfBirth(e.target.value);
  const age = new Date().getFullYear() - new Date(dateOfBirth).getFullYear();
  const name = `${modelName}[${fieldName}]`;

  if (!disabled) {
    if (window["CTAFormValidation"] === undefined) {
      window["CTAFormValidation"] = [];
    }

    if (age < 18) {
      window["CTAFormValidation"].push(name);
    } else {
      window["CTAFormValidation"] = window["CTAFormValidation"].filter((field) => field !== name);
    }
  }
  React.useEffect(() => {
    onChange(`${prefix}${fieldName}`, dateOfBirth);
  }, [dateOfBirth]);

  return (
    <div className="form-floating mb-2">
      <input
        type="date"
        id={name}
        name={name}
        onChange={handleChange}
        className="form-control"
        value={dateOfBirth}
        disabled={disabled}
        required={required}
      />
      <label htmlFor={name} className="form-label">Date of Birth{required ? '*' : ''}</label>
      {!disabled && age < 18 && <div className="alert alert-warning">{errorMessage}</div>}
    </div>
  );
}

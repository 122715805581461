import React from 'react';
import { CountryField, CountriesWithStates } from '../CountryField/CountryField';
import { StateField } from '../StateField/StateField';

interface AddressFieldsProps {
  selectedStreetAddress?: string;
  selectedAddressLine2?: string;
  selectedCity?: string;
  selectedState: string;
  selectedCountry: string;
  selectedZip?: string;
  modelName: string;
  addressLine2Name: string;
  prefix?: string;
  onChange: any;
  fieldsDisabled?: boolean;
  fieldsRequired?: boolean;
}

export const AddressFields = ({
  selectedStreetAddress = '',
  selectedAddressLine2 = '',
  selectedCity = '',
  selectedState = '',
  selectedCountry = '',
  selectedZip = '',
  modelName = '',
  addressLine2Name = 'address_line_2',
  prefix = '',
  onChange = () => { },
  fieldsDisabled = false,
  fieldsRequired = true
}: AddressFieldsProps) => {
  const [country, setCountry] = React.useState(selectedCountry);
  const [state, setState] = React.useState(selectedState);
  const [streetAddress, setStreetAddress] = React.useState(selectedStreetAddress);
  const [addressLine2, setAddressLine2] = React.useState(selectedAddressLine2);
  const [city, setCity] = React.useState(selectedCity);
  const [zip, setZip] = React.useState(selectedZip);

  const selectedCountryObject = CountriesWithStates.find(c => c.name === country);
  const selectedCountryStates: Array<string> | undefined = selectedCountryObject ? selectedCountryObject.states : [];

  React.useEffect(() => {
    onChange(`${prefix}country`, country);
  }, [country]);

  React.useEffect(() => {
    onChange(`${prefix}state`, state);
  }, [state]);

  React.useEffect(() => {
    onChange(`${prefix}street_address`, streetAddress);
  }, [streetAddress]);

  React.useEffect(() => {
    onChange(`${prefix}${addressLine2Name}`, addressLine2);
  }, [addressLine2]);

  React.useEffect(() => {
    onChange(`${prefix}city`, city);
  }, [city]);

  React.useEffect(() => {
    onChange(`${prefix}zip`, zip);
  }, [zip]);

  return (
    <div>
      <CountryField
        modelName={modelName}
        selectedCountry={country}
        setCountryFunction={setCountry}
        prefix={prefix}
        disabled={fieldsDisabled}
        required={fieldsRequired}
      />
      <StandardFormTextField
        fieldName={`${modelName}[${prefix}street_address]`}
        placeholder="Address*"
        value={streetAddress}
        onChange={(e) => setStreetAddress(e.target.value)}
        disabled={fieldsDisabled}
        required={fieldsRequired}
      />
      <StandardFormTextField
        fieldName={`${modelName}[${prefix}${addressLine2Name}]`}
        placeholder="Address 2"
        value={addressLine2}
        disabled={fieldsDisabled}
        required={false}
        onChange={(e) => setAddressLine2(e.target.value)}
      />
      <StateField
        modelName={modelName}
        states={selectedCountryStates}
        selectedState={state}
        setStateFunction={setState}
        prefix={prefix}
        disabled={fieldsDisabled}
        required={fieldsRequired}
      />
      <StandardFormTextField
        fieldName={`${modelName}[${prefix}city]`}
        placeholder="City*"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        disabled={fieldsDisabled}
        required={fieldsRequired}
      />
      <StandardFormTextField
        fieldName={`${modelName}[${prefix}zip]`}
        placeholder="Zip Code*"
        value={zip}
        onChange={(e) => setZip(e.target.value)}
        disabled={fieldsDisabled}
        required={fieldsRequired}
      />
    </div>
  );
}

const StandardFormTextField = ({
  fieldName,
  placeholder,
  type = 'text',
  value,
  onChange,
  disabled = false,
  required = true
}: {
  fieldName: string,
  placeholder: string,
  type?: string,
  value?: string,
  onChange?: any,
  disabled?: boolean,
  required?: boolean
}) => {
  return (
    <div className="form-floating mb-2">
      <input type={type} placeholder={placeholder} name={fieldName} className="form-control" value={value} onChange={onChange} disabled={disabled} required={required && !disabled} />
      <label for={fieldName}>{placeholder}</label>
    </div>
  );
}

import React from 'react';

export const OnboardingExemptionCheck = ({
  selectedValue,
  setState,
  disabled = false
}) => {
  return (
    <div>
      {exemptions.map(([value, label, link], index) => (
        <div key={index}>
          <input
            type="radio"
            id={value}
            // name="company_information[exemption_reason]"
            value={value}
            disabled={disabled}
            // Set state.company_information.exemption_reason to the selected value
            onClick={() => setState(prevState => ({
              ...prevState,
              ['company_information[exemption_reason]']: value
            }))}
            checked={selectedValue === value}
          />
          <label
            htmlFor={value}
            className={disabled ? 'mx-2 opacity-50' : 'mx-2'}
          >
            {label}
          </label>
          {link && <>
            (<a href={link} target="_blank">See Exemption Criteria</a>)
          </>}
        </div>
      ))}
      <input type="hidden" name="company_information[exemption_reason]" value={selectedValue} />
    </div>
  );
};

const exemptions = [
  ['Securities reporting issuer', 'Securities reporting issuer',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=12'],
  ['Governmental authority', 'Governmental authority',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=12'],
  ['Bank', 'Bank',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=12'],
  ['Credit union', 'Credit union',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=12'],
  ['Depository institution holding company', 'Depository institution holding company',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=13'],
  ['Money services business', 'Money services business',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=13'],
  ['Broker or dealer in securities', 'Broker or dealer in securities',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=13'],
  ['Securities exchange or clearing agency', 'Securities exchange or clearing agency',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=13'],
  ['Other Exchange Act registered entity', 'Other Exchange Act registered entity',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=14'],
  ['Investment company or investment adviser', 'Investment company or investment adviser',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=14'],
  ['Venture capital fund adviser', 'Venture capital fund adviser',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=14'],
  ['Insurance company', 'Insurance company',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=14'],
  ['State-licensed insurance producer', 'State-licensed insurance producer',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=15'],
  ['Commodity Exchange Act registered entity', 'Commodity Exchange Act registered entity',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=15'],
  ['Accounting firm', 'Accounting firm',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=16'],
  ['Public utility', 'Public utility',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=16'],
  ['Financial market utility', 'Financial market utility',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=16'],
  ['Pooled investment vehicle', 'Pooled investment vehicle',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=17'],
  ['Tax-exempt entity', 'Tax-exempt entity',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=18'],
  ['Entity assisting a tax-exempt entity', 'Entity assisting a tax-exempt entity',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=18'],
  ['Large operating company', 'Large operating company',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=19'],
  ['Subsidiary of certain exempt entities', 'Subsidiary of certain exempt entities',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=20'],
  ['Inactive entity', 'Inactive entity',
    'https://www.fincen.gov/sites/default/files/shared/BOI_Small_Compliance_Guide_FINAL_Sept_508C.pdf#page=21']
]

export default OnboardingExemptionCheck;
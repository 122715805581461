// React Component
// Info Box using Bootstrap styles
// Info icon that when a user hovers over it, displays a tooltip with the message

import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsInfoCircle } from 'react-icons/bs';

export const InfoBox = ({ message }) => {
  const renderTooltip = (props) => {
    const tooltipProps = { ...props };
    return (
      <Tooltip id="button-tooltip" {...tooltipProps}>
        {message}
      </Tooltip >
    );
  }

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <span><BsInfoCircle /></span>
    </OverlayTrigger>
  );
}

export const ReportingCompanyInfoBox = () => {
  return (
    <InfoBox message={
      <>
        <p>A “Reporting Company” is any (a) domestic entity that is created by the filing of a document with a secretary of state or similar office under the laws of a State or Indian tribe, or (b) foreign entity that is (i) formed under the law of a foreign country; and (ii) is registered to do business in any State or tribunal jurisdiction by the filing of a document with a secretary of state or similar office under the law of a State or Indian tribe.</p>

        <p>“State” means any state of the United States, the District of Columbia, the Commonwealth of Puerto Rico, the Commonwealth of the Northern Mariana Islands, American Samoa, Guam, the United States Virgin Islands, and any other commonwealth, territory, or possession of the United States.</p>

        <p>“Indian tribe” has the meaning given to such term in section 102 of the Federally Recognized Indian Tribe List Act of 1994 (25 U.S.C. 5130).</p>
      </>
    } />
  );
}

export const CompanyApplicantInfoBox = () => {
  return (
    <InfoBox message={
      <>
        <p>
          The “Company Applicant” is the individual who directly filed the document that created or registered the Reporting Company.  If more than one individual is involved in the filing of such document, then the individual primarily responsible for directing or controlling the filing of the document is also a Company Applicant.  A Reporting Company cannot have more than two (2) Company Applicants.
        </p>
      </>
    } />
  );
}

export const BeneficialOwnerInfoBox = () => {
  return (
    <InfoBox message={
      <>
        <p>
          A “Beneficial Owner” is any individual who, directly or indirectly, (a) owns or controls at least twenty-five percent (25%) of the ownership interests of a Reporting Company; or (b) exercises substantial control over a Reporting Company.
        </p>

        <p>
          “Ownership interest” of a Reporting Company can occur through equity, stock, profit interest, trust arrangement, convertible instrument, and/or many other forms of ownership.  Please see 31 CFR 1010.380(d)(2) for additional examples.
        </p>

        <p>
          “Substantial control” of a Reporting Company can occur by serving as a senior officer of a Reporting Company, having authority over the appointment or removal of any senior officer or a majority of the board of directors, directing or determining important decisions of the Reporting Company, and/or many other forms of substantial control.  Please see 31 CFR 1010.380(d)(1) for additional examples.

        </p>
      </>
    } />
  );
}

export const CTARepInfoBox = () => {
  return (
    <InfoBox message={
      <>
        <p>The “CTA Compliance Representative” (a Teeple Hall, LLP defined term) is a natural person who:</p>
        <ul>
          <li>Manages the collection of all beneficial ownership information (“BOI”) of the Reporting Company;</li>
          <li>Monitors such BOI for any changes and submits BOI Reports to FinCEN on behalf of the Reporting Company, as required to comply with the parameters of the CTA; and</li>
          <li>Has the authority to bind the Reporting Company to any and all agreements with Teeple Hall, LLP concerning the Corporate Transparency Act.</li>
        </ul>
      </>
    } />
  );
}

export default InfoBox;
import React from 'react';

import { QuestionTextField } from './QuestionTextField';
import { QuestionDateField } from './QuestionDateField';
import { QuestionCheckbox } from './QuestionCheckbox';
import { QuestionCheckboxes } from './QuestionCheckboxes';
import { QuestionSelect } from './QuestionSelect';
import { CTAComplianceRepresentativeInformationHeader, CompanyCity, CompanyState, CompanyStreetAddress, CompanyStreetAddress2, CompanyZip, ComplianceRepresentativeFirstName, ComplianceRepresentativeLastName, ComplianceRepresentativeMiddleName, ComplianceRepresentativeSuffix, ConfirmContinue, DateOfIncorporation, DateOfIncorporationWarning, DateOfIncorporationWarningCheckbox, EntityCreateBySoS, ExemptionDetermination, ExemptionQuestion, ExemptionWillBeCollectedLater, ExemptionsList, ExemptionsListRadios, HighRiskCountries, HighRiskCountriesList, NameOfReportingCompany, OFACQuestion, ReportingCompanyHeader, SubmitButton, TrustOwnsCompany, TypeOfEntity, TypeOfEntityOther } from './OnboardingQuestions';

const initialState = (profile_data: any, questions) => {
  console.log("Initial State", profile_data, questions);
  // Get keys and values under company_information
  const company_information = profile_data.company_information || {};
  // Change key named to company_information[keyname]
  const company_information_keys = Object.keys(company_information).reduce((acc, key) => {
    acc[`company_information[${key}]`] = company_information[key];
    return acc;
  }, {});

  // Delete company_information key from profile_data
  delete profile_data.company_information;

  // Convert the rest of the keys to profile[keyname]
  const profile_keys = Object.keys(profile_data).reduce((acc, key) => {
    acc[`profile[${key}]`] = profile_data[key];
    return acc;
  }, {});

  // Get all question name values and delete any keys that arent in that list
  const data = { ...company_information_keys, ...profile_keys };
  const question_names = questions.map(question => question.name);

  console.log("Data", data);

  // Delete any keys that arent in the questions list
  Object.keys(data).forEach(key => {
    if (!question_names.includes(key)) {
      delete data[key];
    }
  });

  console.log("Data after Purge", data);

  return data;
}

const UpdateProfileFn = (state, update_profile_path, form_authenticity_token) => {
  // Change keys from profile[keyname] and company_information[keyname] to an object:
  // { profile: { keyname: value, company_information_attributes: { keyname: value } } }
  // So that it can be sent to Rails PATCH model


  const profile = Object.keys(state).reduce((acc, key) => {
    if (key.includes('company_information')) {
      if (!acc['company_information_attributes']) {
        acc['company_information_attributes'] = {};
      }
      acc['company_information_attributes'][key.replace('company_information[', '').replace(']', '')] = state[key];

      console.log("Company Information", acc['company_information_attributes']);
    } else {
      acc[key.replace('profile[', '').replace(']', '')] = state[key];
    }
    return acc;
  }, {});

  console.log("Submitting Profile", `${update_profile_path}.json`, { profile: profile });

  fetch(`${update_profile_path}.json`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': form_authenticity_token
    },
    body: JSON.stringify({ profile: profile })
  })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

export const Onboarding = (props: any) => {
  const { profile_data, update_profile_path, form_authenticity_token, submit_profile_path } = props;

  console.log("Profile Data", profile_data)

  const [state, setState] = React.useState(
    initialState(profile_data, QuestionsInit({}))
  );

  console.log("Initialized State", state);

  const Questions = QuestionsInit({ setState, state });

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(state)
      UpdateProfileFn(state, update_profile_path, form_authenticity_token)
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [state])

  return (
    <div>
      <h1 className="text-center">Onboarding</h1>
      <form action={submit_profile_path} method="post">
        {/* Form Authenticity Token */}
        <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
        {/* Questions */}
        {Questions.map((question, index) => {
          // If there are show conditions, check if the question should be shown, otherwise return true
          const shouldShow = question.showConditions
            ? question.showConditions.every(condition => condition.showCondition() === true)
            : true;

          if (shouldShow === false) {
            return <></>;
          } else if (question.questionType === 'text') {
            return <QuestionTextField key={index} question={question} setState={setState} state={state} />;
          } else if (question.questionType === 'date') {
            return <QuestionDateField key={index} question={question} setState={setState} state={state} />;
          } else if (question.questionType === 'checkbox') {
            return <QuestionCheckbox key={index} question={question} setState={setState} state={state} />;
          } else if (question.questionType === 'checkboxes') {
            return <QuestionCheckboxes key={index} question={question} setState={setState} state={state} />;
          } else if (question.questionType === 'select') {
            return <QuestionSelect key={index} question={question} setState={setState} state={state} />;
          } else if (question.questionType === 'jsx') {
            return question.content;
          }
        })}

        <input type="hidden" name="profile[onboarding_complete]" value="true" />

      </form>
    </div>
  );
}

export interface ErrorCondition {
  errorContent: string;
  condition: (value: string) => boolean;
}

export interface ShowCondition {
  showCondition: boolean;
}

export interface Option {
  value: string;
  label: string;
}

export interface Question {
  label: string;
  name: string;
  errorConditions: ErrorCondition[];
  questionType: string;
  description?: string;
  showConditions?: ShowCondition[];
  options?: Option[];
  alertType?: string;
}

const QuestionsInit = ({ setState, state }: { setState?: any, state?: any }) => {
  console.log("Updating Questions with", state);
  return [

    // 4. Information
    // a.	“Name of Reporting Company:” (text field)
    NameOfReportingCompany,

    // b.	“Date of Incorporation/Formation:” (date field)
    DateOfIncorporation,
    DateOfIncorporationWarning(state),
    DateOfIncorporationWarningCheckbox(state),
    CompanyStreetAddress,
    CompanyStreetAddress2,
    CompanyCity,
    CompanyState,
    CompanyZip,

    // c. Name of CTA Compliance Representative
    // CTA Compliance Representative Information Header
    CTAComplianceRepresentativeInformationHeader,
    ComplianceRepresentativeFirstName,
    ComplianceRepresentativeMiddleName,
    ComplianceRepresentativeLastName,
    ComplianceRepresentativeSuffix,

    // 5. Reporting Company
    ReportingCompanyHeader,
    TypeOfEntity,
    TypeOfEntityOther(state),
    EntityCreateBySoS(state),
    ConfirmContinue(state),

    // Exemptions
    ExemptionQuestion,
    ExemptionWillBeCollectedLater(state),
    ExemptionDetermination(state),
    // ExemptionsList,
    ExemptionsListRadios(state, setState),

    // High Risks
    HighRiskCountries,
    HighRiskCountriesList,
    OFACQuestion,
    TrustOwnsCompany,
    SubmitButton(state)

  ];
}

import React from 'react';

interface CountryFieldProps {
  selectedCountry: string;
  modelName: string;
  fieldName: string;
  setCountryFunction: (country: string) => void;
  prefix?: string;
  disabled?: boolean;
  required?: boolean;
}

export const CountryField = ({
  selectedCountry,
  modelName,
  fieldName = 'country',
  prefix = '',
  setCountryFunction,
  disabled = false,
  required = true }: CountryFieldProps) => {

  console.log('CountryField', selectedCountry, modelName);

  const countriesOptions = CountriesWithStates.map((country) => {
    const selected = country.name === selectedCountry ? 'selected' : '';
    return (
      <option key={country.name} value={country.name} selected={selected}>
        {country.name}
      </option >
    );
  });

  return (
    <div className="form-floating mb-2">
      <select
        className="form-select"
        id="country"
        onChange={(e) => setCountryFunction(e.target.value)}
        name={`${modelName}[${prefix}${fieldName}]`}
        disabled={disabled}
        required={required}
      >
        <option value="">Select a country</option>
        {countriesOptions}
      </select>
      <label className="form-label">Country{required ? '*' : ''}</label>
    </div>
  );
};

interface CountryWithStates {
  name: string;
  states?: Array<string>;
}

export const CountriesWithStates: CountryWithStates[] = [
  {
    name: 'United States of America', states: [
      "Alabama",
      "Alaska",
      "Armed Forces Europe/Middle-East/Canada",
      "Armed Forces Americas (except Canada)",
      "Armed Forces Pacific",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming"
    ]
  },
  { name: 'Afghanistan', states: [] },
  { name: 'Albania', states: [] },
  { name: 'Algeria', states: [] },
  { name: 'American Samoa', states: [] },
  { name: 'Andorra', states: [] },
  { name: 'Angola', states: [] },
  { name: 'Anguilla', states: [] },
  { name: 'Antarctica', states: [] },
  { name: 'Antigua And Barbuda', states: [] },
  { name: 'Argentina', states: [] },
  { name: 'Armenia', states: [] },
  { name: 'Aruba', states: [] },
  { name: 'Australia', states: [] },
  { name: 'Austria', states: [] },
  { name: 'Azerbaijan', states: [] },
  { name: 'Bahamas', states: [] },
  { name: 'Bahrain', states: [] },
  { name: 'Bangladesh', states: [] },
  { name: 'Barbados', states: [] },
  { name: 'Belarus', states: [] },
  { name: 'Belgium', states: [] },
  { name: 'Belize', states: [] },
  { name: 'Benin', states: [] },
  { name: 'Bermuda', states: [] },
  { name: 'Bhutan', states: [] },
  { name: 'bissau', states: [] },
  { name: 'Bolivia, Plurinational State Of', states: [] },
  { name: 'Bonaire, Sint Eustatius And Saba', states: [] },
  { name: 'Bosnia And Herzegovina', states: [] },
  { name: 'Botswana', states: [] },
  { name: 'Bouvet Island', states: [] },
  { name: 'Brazil', states: [] },
  { name: 'British Indian Ocean Territory', states: [] },
  { name: 'Brunei Darussalam', states: [] },
  { name: 'Bulgaria', states: [] },
  { name: 'Burkina Faso', states: [] },
  { name: 'Burundi', states: [] },
  { name: 'Cambodia', states: [] },
  { name: 'Cameroon', states: [] },
  {
    name: 'Canada', states: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territory",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Yukon Territory",
      "Quebec",
      "Saskatchewan",
      "Prince Edward island",
    ]
  },
  { name: 'Cape Verde', states: [] },
  { name: 'Cayman Islands', states: [] },
  { name: 'Central African Republic', states: [] },
  { name: 'Chad', states: [] },
  { name: 'Chile', states: [] },
  { name: 'China', states: [] },
  { name: 'Christmas Island', states: [] },
  { name: 'Cocos (Keeling) Islands', states: [] },
  { name: 'Colombia', states: [] },
  { name: 'Comoros', states: [] },
  { name: 'Congo', states: [] },
  { name: 'Congo, The Democratic Republic Of The', states: [] },
  { name: 'Cook Islands', states: [] },
  { name: 'Costa Rica', states: [] },
  { name: 'Croatia', states: [] },
  { name: 'Cuba', states: [] },
  { name: 'Curaçao', states: [] },
  { name: 'Cyprus', states: [] },
  { name: 'Czech Republic', states: [] },
  { name: "Côte D'ivoire", states: [] },
  { name: 'Denmark', states: [] },
  { name: 'Djibouti', states: [] },
  { name: 'Dominica', states: [] },
  { name: 'Dominican Republic', states: [] },
  { name: 'Ecuador', states: [] },
  { name: 'Egypt', states: [] },
  { name: 'El Salvador', states: [] },
  { name: 'Equatorial Guinea', states: [] },
  { name: 'Eritrea', states: [] },
  { name: 'Estonia', states: [] },
  { name: 'Ethiopia', states: [] },
  { name: 'Falkland Islands (Malvinas)', states: [] },
  { name: 'Faroe Islands', states: [] },
  { name: 'Fiji', states: [] },
  { name: 'Finland', states: [] },
  { name: 'France', states: [] },
  { name: 'French Guiana', states: [] },
  { name: 'French Polynesia', states: [] },
  { name: 'French Southern Territories', states: [] },
  { name: 'Gabon', states: [] },
  { name: 'Gambia', states: [] },
  { name: 'Georgia', states: [] },
  { name: 'Germany', states: [] },
  { name: 'Ghana', states: [] },
  { name: 'Gibraltar', states: [] },
  { name: 'Greece', states: [] },
  { name: 'Greenland', states: [] },
  { name: 'Grenada', states: [] },
  { name: 'Guadeloupe', states: [] },
  { name: 'Guam', states: [] },
  { name: 'Guatemala', states: [] },
  { name: 'Guernsey', states: [] },
  { name: 'Guinea', states: [] },
  { name: 'Guyana', states: [] },
  { name: 'Haiti', states: [] },
  { name: 'Heard Island And Mcdonald Islands', states: [] },
  { name: 'Holy See (Vatican City State)', states: [] },
  { name: 'Honduras', states: [] },
  { name: 'Hong Kong', states: [] },
  { name: 'Hungary', states: [] },
  { name: 'Iceland', states: [] },
  { name: 'India', states: [] },
  { name: 'Indonesia', states: [] },
  { name: 'Iran, Islamic Republic Of', states: [] },
  { name: 'Iraq', states: [] },
  { name: 'Ireland', states: [] },
  { name: 'Isle Of Man', states: [] },
  { name: 'Israel', states: [] },
  { name: 'Italy', states: [] },
  { name: 'Jamaica', states: [] },
  { name: 'Japan', states: [] },
  { name: 'Jersey', states: [] },
  { name: 'Jordan', states: [] },
  { name: 'Kazakhstan', states: [] },
  { name: 'Kenya', states: [] },
  { name: 'Kiribati', states: [] },
  { name: "Korea, Democratic People's Republic Of", states: [] },
  { name: 'Korea, Republic Of', states: [] },
  { name: 'Kuwait', states: [] },
  { name: 'Kyrgyzstan', states: [] },
  { name: "Lao People's Democratic Republic", states: [] },
  { name: 'Latvia', states: [] },
  { name: 'Lebanon', states: [] },
  { name: 'Lesotho', states: [] },
  { name: 'leste', states: [] },
  { name: 'Liberia', states: [] },
  { name: 'Libya', states: [] },
  { name: 'Liechtenstein', states: [] },
  { name: 'Lithuania', states: [] },
  { name: 'Luxembourg', states: [] },
  { name: 'Macao', states: [] },
  { name: 'Macedonia, The Former Yugoslav Republic Of', states: [] },
  { name: 'Madagascar', states: [] },
  { name: 'Malawi', states: [] },
  { name: 'Malaysia', states: [] },
  { name: 'Maldives', states: [] },
  { name: 'Mali', states: [] },
  { name: 'Malta', states: [] },
  { name: 'Marshall Islands', states: [] },
  { name: 'Martinique', states: [] },
  { name: 'Mauritania', states: [] },
  { name: 'Mauritius', states: [] },
  { name: 'Mayotte', states: [] },
  {
    name: 'Mexico', states: [
      "Chihuahua",
      "Sonora",
      "Coahuila",
      "Durango",
      "Oaxaca",
      "Tamaulipas",
      "Jalisco",
      "Zacatecas",
      "Baja California Sur",
      "Chiapas",
      "Veracruz",
      "Baja California",
      "Nuevo León",
      "Guerrero",
      "San Luis Potosí",
      "Michoacán",
      "Sinaloa",
      "Campeche",
      "Quintana Roo",
      "Yucatán",
      "Puebla",
      "Guanajuato",
      "Nayarit",
      "Tabasco",
      "México",
      "Hidalgo",
      "Querétaro",
      "Colima",
      "Aguascalientes",
      "Morelos",
      "Tlaxcala",
      "Mexican Federal District",
    ]
  },
  { name: 'Micronesia Federated States', states: [] },
  { name: 'Micronesia, Federated States', states: [] },
  { name: 'Moldova, Republic Of', states: [] },
  { name: 'Monaco', states: [] },
  { name: 'Mongolia', states: [] },
  { name: 'Montenegro', states: [] },
  { name: 'Montserrat', states: [] },
  { name: 'Morocco', states: [] },
  { name: 'Mozambique', states: [] },
  { name: 'Myanmar', states: [] },
  { name: 'Namibia', states: [] },
  { name: 'Nauru', states: [] },
  { name: 'Nepal', states: [] },
  { name: 'Netherlands', states: [] },
  { name: 'New Caledonia', states: [] },
  { name: 'New Zealand', states: [] },
  { name: 'Nicaragua', states: [] },
  { name: 'Niger', states: [] },
  { name: 'Nigeria', states: [] },
  { name: 'Niue', states: [] },
  { name: 'Norfolk Island', states: [] },
  { name: 'Northern Mariana Islands', states: [] },
  { name: 'Norway', states: [] },
  { name: 'Oman', states: [] },
  { name: 'Pakistan', states: [] },
  { name: 'Palau', states: [] },
  { name: 'Palestinian Territory, Occupied', states: [] },
  { name: 'Panama', states: [] },
  { name: 'Papua New Guinea', states: [] },
  { name: 'Paraguay', states: [] },
  { name: 'Peru', states: [] },
  { name: 'Philippines', states: [] },
  { name: 'Pitcairn', states: [] },
  { name: 'Poland', states: [] },
  { name: 'Portugal', states: [] },
  { name: 'Puerto Rico', states: [] },
  { name: 'Qatar', states: [] },
  { name: 'Romania', states: [] },
  { name: 'Russian Federation', states: [] },
  { name: 'Rwanda', states: [] },
  { name: 'Réunion', states: [] },
  { name: 'Saint Barthélemy', states: [] },
  { name: 'Saint Helena, Ascension And Tristan Da Cunha', states: [] },
  { name: 'Saint Kitts And Nevis', states: [] },
  { name: 'Saint Lucia', states: [] },
  { name: 'Saint Martin (french Part)', states: [] },
  { name: 'Saint Pierre And Miquelon', states: [] },
  { name: 'Saint Vincent And The Grenadines', states: [] },
  { name: 'Samoa', states: [] },
  { name: 'San Marino', states: [] },
  { name: 'Sao Tome And Principe', states: [] },
  { name: 'Saudi Arabia', states: [] },
  { name: 'Senegal', states: [] },
  { name: 'Serbia', states: [] },
  { name: 'Seychelles', states: [] },
  { name: 'Sierra Leone', states: [] },
  { name: 'Singapore', states: [] },
  { name: 'Sint Maarten (dutch Part)', states: [] },
  { name: 'Slovakia', states: [] },
  { name: 'Slovenia', states: [] },
  { name: 'Solomon Islands', states: [] },
  { name: 'Somalia', states: [] },
  { name: 'South Africa', states: [] },
  { name: 'South Georgia And The South Sandwich Islands', states: [] },
  { name: 'South Sudan', states: [] },
  { name: 'Spain', states: [] },
  { name: 'Sri Lanka', states: [] },
  { name: 'Sudan', states: [] },
  { name: 'Suriname', states: [] },
  { name: 'Svalbard And Jan Mayen', states: [] },
  { name: 'Swaziland', states: [] },
  { name: 'Sweden', states: [] },
  { name: 'Switzerland', states: [] },
  { name: 'Syrian Arab Republic', states: [] },
  { name: 'Taiwan', states: [] },
  { name: 'Tajikistan', states: [] },
  { name: 'Tanzania, United Republic Of', states: [] },
  { name: 'Thailand', states: [] },
  { name: 'Togo', states: [] },
  { name: 'Tokelau', states: [] },
  { name: 'Tonga', states: [] },
  { name: 'Trinidad And Tobago', states: [] },
  { name: 'Tunisia', states: [] },
  { name: 'Turkey', states: [] },
  { name: 'Turkmenistan', states: [] },
  { name: 'Turks And Caicos Islands', states: [] },
  { name: 'Tuvalu', states: [] },
  { name: 'U.S. Virgin Islands', states: [] },
  { name: 'Uganda', states: [] },
  { name: 'Ukraine', states: [] },
  { name: 'United Arab Emirates', states: [] },
  { name: 'United Kingdom', states: [] },
  { name: 'Uruguay', states: [] },
  { name: 'Uzbekistan', states: [] },
  { name: 'Vanuatu', states: [] },
  { name: 'Venezuela, Bolivarian Republic Of', states: [] },
  { name: 'Viet Nam', states: [] },
  { name: 'Virgin Islands, British', states: [] },
  { name: 'Wallis And Futuna', states: [] },
  { name: 'Western Sahara', states: [] },
  { name: 'Yemen', states: [] },
  { name: 'Zambia', states: [] },
  { name: 'Zimbabwe', states: [] },
  { name: 'Åland Islands', states: [] }
];
